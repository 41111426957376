import React, { useState } from "react"
import { Link } from "~components/linkWithPreviousUrl/linkWithPreviousUrl"
import { navigate } from "@reach/router"
import { motion, AnimatePresence } from "framer-motion"
import { useLockBodyScroll } from "react-use"
import classnames from "classnames"

import Footer from "~components/footer/footer"

/* Import Local Components */
import HomeIconLink from "../homeIconLink/homeIconLink"
import VIPNavigation from "../vipNavigation/vipNavigation"
import Links from "../navigationLinks/navigationLinks"
import DotLinks from '../dotLinks/dotLinks'

import NavigationColumns from './columns'

/* Import Global Animation(s) */
import elementTransitionAnimation from "~animations/elementTransition"

/* Import Global Asset(s) */
import CrossSvgSrc from "~assets/images/close.svg"
import HamburgerSvgSrc from "~assets/images/hamburger.svg"
import HamburgerSvgSrcDesktop from "~assets/images/hamburger-desktop.svg"

/* Import Local Style(s) */
import "./column-navigation.scss"


const ColumnNavigation = ({ pathname }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const pageTitles = {
    "/": "Untitled Art",
    "/program": "Program",
    "/program/": "Program",
    "/about": "About",
    "/about/": "About",
    "/news": "News",
    "/news/": "News",
    "/edits": "Untitled Edit",
    "/edits/": "Untitled Edit",
    "/podcast": "Podcast",
    "/podcast/": "Podcast",
    "/visit": "Visit",
    "/visit/": "Visit",
    
    "/travel": "Travel",
    "/travel/": "Travel",

    "/tickets": "Tickets",
    "/tickets/": "Tickets",

    "/partners": "Partners",
    "/partners/": "Partners",
    "/galleries": "Galleries",
    "/galleries/": "Galleries",
    "/vip": "VIP",
    "/vip/": "VIP",
    "/vip/reset": "VIP Reset",
    "/vip/reset/": "VIP Reset",
    "/vip/profile": "VIP",
    "/vip/profile/": "VIP",
    "/vip/profile/edit": "VIP",
    "/vip/profile/edit/": "VIP",
    "/virtual-fair": "Virtual Fair",
    "/virtual-fair/": "Virtual Fair",
    "/fair-guide": "Fair Guide",
    "/fair-guide/": "Fair Guide",
  }

  const pageTitle = pageTitles[pathname] || "Untitled Art"

  useLockBodyScroll(isMenuOpen)

  const toggleMenu = () => {
    navigate(pathname)
    setIsMenuOpen(!isMenuOpen);
    
  }

  return (<nav className={classnames('column-navigation', {'is_open': isMenuOpen})} >
  	<HomeIconLink />
    <VIPNavigation isOpen={isMenuOpen} />

    <div id="column_title">{pageTitle}</div>

    <NavigationColumns pathname={pathname} />

    
    <Footer hideLegal={true} hideVip={true} />  
    


    <button 
    	onClick={toggleMenu} 
    	className="menu_hamburger"
    >
    	<AnimatePresence exitBeforeEnter>
        {!isMenuOpen && (
          <motion.img
            src={HamburgerSvgSrc}
            alt="hamburger"
            key="hamburger"
            initial="initial"
            animate="animate"
            exit="exit"
            className="menu-open mobile-only"
            variants={elementTransitionAnimation.variants}
          />
        )}
        {!isMenuOpen && (
          <motion.img
            src={HamburgerSvgSrcDesktop}
            alt="hamburger"
            key="hamburgertwo"
            initial="initial"
            animate="animate"
            className="menu-open desktop-only"
            exit="exit"
            variants={elementTransitionAnimation.variants}
          />
        )}

        {isMenuOpen && (
          <motion.img
            src={CrossSvgSrc}
            className="close"
            alt="cross"
            key="cross"
            initial="initial"
            animate="animate"
            exit="exit"
            variants={elementTransitionAnimation.variants}
          />
        )}
      </AnimatePresence>
    </button>

  </nav>)

}

export default ColumnNavigation