import React, { useState } from "react"
import { Link } from "~components/linkWithPreviousUrl/linkWithPreviousUrl"
import { useHomePage, useWebsiteConfiguration } from "~queries/hooks"
import classnames from "classnames"

const NavigationColumns = ({ pathname }) => {

	const { 
		displayProgramEvents, 
		displayTickets, 
		displayFairGuide, 
		fairGuideButton, 
		displayVirtual, 
		displayHotel, 
		hotelButton,  // used for color highlight
		displayEdits 
	} = useHomePage()

	const { coverAnimation } = useWebsiteConfiguration()

return (<section className="nav_columns font-size--small">
	
		<aside className="col-3-13 mcol-6-6 morder-1">
			{ coverAnimation && coverAnimation.map((a, i) => (<p 
					key={'animation_'+i}
					className={classnames({'dark_gray': i + 1 == coverAnimation.length})}
				>{a}</p>))}
		</aside>

		<ul className="link_dots col-3-13 offset-1-2 moffset-0 mcol-3-6 morder-3">
			{displayFairGuide && <li className="link_dot"><Link to="/fair-guide">Fair Guide</Link></li>}
			{displayProgramEvents && <li className="link_dot"><Link to="/program">Program</Link></li>}
			{displayTickets && <li className="link_dot link_dot--black"><Link to="/tickets">Buy Tickets</Link></li>}
			{displayHotel && <li className="link_dot"><Link to="/travel">Travel</Link></li>}
		</ul>
		<ul className="col-2-13 offset-1 moffset-0 mcol-3-6 morder-2">
			<li><Link to="/about">About</Link></li>
			<li><Link to="/visit">Visit</Link></li>
			{displayVirtual && <li className="whitespace"><Link to="/virtual-fair">Virtual Fair</Link></li>}
		</ul>
		<ul className="col-2-13 mcol-3-6 morder-4 moffset-y-1-2">
			<li><Link to="/galleries">Galleries</Link></li>
			<li><Link to="/partners">Partners</Link></li>
		</ul>
		<ul className="col-3-13 mcol-6-6 morder-5 moffset-y-1-2">
			{displayEdits && <li><Link to="/edits">Untitled Edit</Link></li>}
			<li><Link to="/news">News</Link></li>
			<li><Link to="/podcast">Podcast</Link></li>
		</ul>

</section>)

}

export default NavigationColumns