import React from "react"
import { Link } from "~components/linkWithPreviousUrl/linkWithPreviousUrl"
import classnames from "classnames"

/* Import Local Styles */
import "./vip-navigation.scss"

const VIPNavigation = ({isOpen}) => (
  <Link to="/vip" className={classnames("navigation__vip", {'font-size--small': isOpen})}>
    <span>VIP</span>
  </Link>
)

export default VIPNavigation
